.containerSidebar {
    scrollbar-width: 8px !important;
    scrollbar-color: #e0e0e0 transparent !important;
    overflow: scroll !important;
}

.containerSidebar::-webkit-scrollbar {
    width: 10px !important;
}

.containerSidebar::-webkit-scrollbar-track {
    background-color: transparent !important;
    margin: 10px !important;
}

.containerSidebar::-webkit-scrollbar-thumb {
    background-color: #e0e0e0 !important;
    border-radius: 20px !important;
}