:root {
    --red-primary: #B50000;
    --blue-primary: #001D7E;

    --red-primary-light: #B50000B9;
    --blue-primary-light: #001D7EB9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
  font-size: 15px;
  font-family: 'DM Sans', sans-serif !important;
}

body {
  scrollbar-width: 8px !important;
  scrollbar-color: #e0e0e0 transparent !important;
  overflow: auto !important;
}

body::-webkit-scrollbar {
  width: 10px !important;
}

body::-webkit-scrollbar-track {
  background-color: transparent !important;
  margin: 10px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #e0e0e0 !important;
  border-radius: 20px !important;
}

.drawer_menu {
    background-color: #fafafa !important;
    border-right: 1px solid #cbcbcb !important;
    max-width: 20rem !important;
    
    li > div[role='button']:hover {
        background-color: #00000012 !important;
    }
}