.header {
    z-index: 1201 !important;

    > .content {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;

        > div {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            gap: 1rem !important;

            .area_btn_header {
                display: flex !important;
                align-items: center !important;
                justify-content: flex-start !important;

                > button > svg {
                    font-size: 2rem !important;
                    color: #fff !important;
                    border-radius: 4px !important;
                }

                // > button + button {
                //     border-left: 1px solid #fff4 !important;
                //     border-radius: 0 !important;
                // }
            }

            > img {
                width: 8rem !important;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .header {
        > .content {
            > div {
                > img {
                    width: 6rem !important;
                }
            }
        }
    }
}