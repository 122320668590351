.btn_next_step::before {
    content: attr(data-web) !important;
}

@media (max-width: 840px) {
    .btn_next_step {
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;

        &::before {
            content: attr(data-mobile) !important;
        }
    }
}