.area_content {
    padding-top: 6rem !important;
    width: calc(100% - 21rem) !important;

    &.no_auth {
        width: 100% !important;
    }
}

@media (max-width: 894px) {
    .area_content {
        padding-top: 4.4rem !important;
        width: 100% !important;
    }
}